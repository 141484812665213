import React from "react";
import { graphql } from "gatsby";

// Components
import { PageMeta } from "@components/pageMeta";
import Layout from "@components/layout";
import SimplePortableText from "@components/sanity/simplePortableText";

const SimplePageTemplate = ({ data: { page } }) => {
  const { seo, title, text } = page || {};

  return (
    <Layout reverseHeader={true} contactFooter={false}>
      <PageMeta {...seo} />
      <div className="mt-72 px-gutter">
        <h1 className="text-dark-green blockH3">{title}</h1>
        <SimplePortableText text={text} className="mt-20" />
      </div>
    </Layout>
  );
};

export default SimplePageTemplate;

export const pageQuery = graphql`
  query simplePageQuery($slug: String!) {
    page: sanitySimplePage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      text: _rawText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
